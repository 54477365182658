import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import logo from '../images/Logo.webp'

export default function Navbar() {
  const [mobile, setMobile] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const openNav = () => {
    setMobile(!mobile)
  }

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled])

  return (
    <>
      {/* DESKTOP MENU */}
      <div
        className={`w-full pt-5 pb-5 hidden md:block z-50 sticky top-0 relative ${
          scrolled ? 'bg-white shadow-sm shadow-blue-100' : 'bg-transparent'
        }`}
      >
        <div className="container mx-auto flex items-center justify-between">
          <div className="logo font-bold text-[30px] ">
            <Link to="/">
              <img
                src={logo}
                width={200}
                height="auto"
                className="w-[150px] lg:w-[200px] h-auto"
                alt="linkly"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="flex items-center gap-4 lg:gap-12">
            <Link
              activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
              className="text-[18px] inter500"
              to="/#linkly-features"
            >
              Features
            </Link>
            <Link
              activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
              className="text-[18px] inter500"
              to="/integrations"
            >
              Integrations
            </Link>
            <Link
              activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
              className="text-[18px] inter500"
              to="/pricing"
            >
              Pricing
            </Link>
            <Link
              activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
              className="text-[18px] inter500"
              to="/support"
            >
              Support
            </Link>
          </div>
          <div className="flex items-center gap-4 lg:gap-7">
            <div className="text-[18px] inter500">
              <Link
                to="https://app.linklyhq.com"
                className="text-[18px] inter500"
              >
                Log in
              </Link>
            </div>
            <div className="bg-blue-500 hover:bg-blue-600 px-5 py-2 rounded-full text-[18px] text-white inter500">
              <Link
                to="https://app.linklyhq.com/l/4a"
                className="text-[18px] inter500"
              >
                <p>Try it Free</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE MENU */}
      <MobileMenu
        mobile={mobile}
        openNav={openNav}
        scrolled={scrolled}
        setMobile={setMobile}
      />
    </>
  )
}

const MobileMenu = ({ mobile, openNav, scrolled, setMobile }) => {
  const location = useLocation()

  useEffect(() => {
    setMobile(false)
  }, [location.pathname, setMobile])

  return (
    <div
      className={`relative pt-5 block md:hidden z-50 sticky top-0 w-full px-3 ${
        mobile ? 'bg-[#D8F3FF] h-screen' : ''
      } ${scrolled ? 'bg-white' : 'bg-transparent'}  `}
    >
      <div
        className={`logo flex justify-between items-center font-bold text-[30px] mb-6 ${
          mobile && ''
        }`}
      >
        <Link to="/">
          <img
            className="w-[100px]"
            width={100}
            height={100}
            src={logo}
            alt="Linkly"
            loading="lazy"
          />
        </Link>
        <div
          role="button"
          tabIndex="0"
          onClick={openNav}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              openNav()
            }
          }}
          className=""
        >
          {mobile ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          )}
        </div>
      </div>
      {mobile && (
        <div className="flex flex-col items-center justify-center gap-12 mt-12">
          <Link
            activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
            className="text-[18px] inter500"
            to="/#linkly-features"
            onClick={openNav}
          >
            Features
          </Link>
          <Link
            activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
            className="text-[18px] inter500"
            to="/integrations"
          >
            Integrations
          </Link>
          <Link
            activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
            className="text-[18px] inter500"
            to="/pricing"
            onClick={openNav}
          >
            Pricing
          </Link>
          <Link
            activeClassName="inter600 text-[14px] !text-[#1d4ed8] link-active"
            className="text-[18px] inter500"
            to="/support"
            onClick={openNav}
          >
            Support
          </Link>
          <div className="flex items-center gap-3">
            <Link
              to="https://app.linklyhq.com"
              className="text-[18px] inter500"
              onClick={openNav}
            >
              Log in
            </Link>
            <div className="bg-[#1361F5] px-5 py-2 rounded-full text-[18px] text-white inter500">
              <Link
                to="https://app.linklyhq.com/l/4a"
                className="text-[18px] inter500"
                onClick={openNav}
              >
                <p>Try it Free</p>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
